
/*.TEXT {*/
/*    font-family: 'Nunito', sans-serif;*/
/*}*/

span, input, button, div {
    font-family: 'Nunito', sans-serif;
    /*font-family: 'Lato', sans-serif;*/
    /*font-size: 40px;*/
}


p {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
}


/*.TXT {*/
/*    font-family: 'Nunito', sans-serif;*/
/*}*/

.wmde-markdown {
    font-family: 'Nunito', sans-serif;
}

.w-md-editor {
    font-family: 'Nunito', sans-serif;
}

/*.MONO {*/
/*    font-family: 'Roboto Mono', monospace;*/
/*}*/